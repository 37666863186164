import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { MappedImage } from '../Image'
import { useSearchParams } from '../../libs/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faCheck,
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

let locationPathname =
  typeof window !== 'undefined' ? window.location.pathname : ''

export const Header = ({
  primaryLogo,
  secondaryLogo,
  headerPrimaryLogo,
  headerSecondaryLogo,
  headerNavItem,
  languageSelectorText,
  languageOptions,
  orgId,
  slug,
  payerLink,
}) => {
  const findHeaderNavLink = headerNavItem?.find(item => item?.navigationLink)
  const url = useSearchParams(
    findHeaderNavLink?.navigationLink,
    orgId,
    payerLink
  )

  const [dropdownIcon, setDropdownIcon] = useState(false)
  const [checkmark, setCheckmark] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions?.[0])

  const navTitle = (
    <span
      className="nav-dropdown-title"
      onClick={() => setDropdownIcon(prev => !prev)}
    >
      {languageSelectorText}
      <FontAwesomeIcon
        className="nav-dropdown-title-fa-icon"
        icon={dropdownIcon ? faChevronUp : faChevronDown}
      />
    </span>
  )
  const handleOnClick = () => {
    setIsClicked(prev => !prev)
    setExpanded(prev => !prev)
  }

  const handleLanguageSelect = eventKey => {
    setSelectedLanguage(eventKey)
    setCheckmark(prev => !prev)
  }

  const getLanguageOption = (urlLanguage, languageOptions) => {
    const options = {
      en: ['english', 'inglés'],
      es: ['español'],
    }

    const availableOptions = options[urlLanguage]
    if (availableOptions) {
      return languageOptions?.find(option =>
        availableOptions?.includes(option?.toLowerCase())
      )
    }

    return null
  }

  useEffect(() => {
    // Check if the URL language matches a language option
    const urlLanguage = locationPathname?.split('/')[1]
    const languageOption = getLanguageOption(urlLanguage, languageOptions)

    if (languageOption) {
      setSelectedLanguage(languageOption)
      setCheckmark(true)
    }
  }, [languageOptions])

  return (
    <Container
      as="header"
      fluid
      style={{ backgroundColor: 'transparent', position: 'absolute' }}
      className="header-outer p-sm-1 p-md-3"
    >
      <Row className="header-inner mx-auto px-sm-1 px-md-4">
        <Col
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className="d-flex bsc-logo-wrapper my-2"
        >
          {(headerPrimaryLogo || primaryLogo) && (
            <MappedImage
              image={headerPrimaryLogo || primaryLogo}
              style={{ paddingRight: '2rem' }}
              alt={`${headerPrimaryLogo?.title || primaryLogo?.title}`}
            />
          )}
        </Col>
        <Col
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className="d-flex justify-content-end wellvolution-logo-wrapper py-3"
          style={{ maxHeight: '75px' }}
        >
          <Col className="d-flex justify-content-md-start justify-content-lg-center">
            {(headerSecondaryLogo || secondaryLogo) && (
              <MappedImage
                image={headerSecondaryLogo || secondaryLogo}
                alt={`${headerSecondaryLogo?.title || secondaryLogo?.title}`}
              />
            )}
          </Col>
          <Col className="header-navbar">
            <Navbar
              className="well-header-btn-wrapper"
              collapseOnSelect
              expand="lg"
              bg="transparent"
              variant="light"
              expanded={expanded}
            >
              <Container fluid className="navbar-container p-0">
                <Navbar.Toggle
                  className="navbar-toggle"
                  aria-controls="responsive-navbar-nav"
                  onClick={handleOnClick}
                >
                  <FontAwesomeIcon
                    className="navbar-burger-menu"
                    icon={!isClicked ? faBars : faTimes}
                  />
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav" expanded="true">
                  <Nav className="nav ms-auto">
                    {headerNavItem?.map(item => {
                      return (
                        <Nav.Link
                          className={`well-header-btn cta`}
                          aria-label={`${item?.navigationTitle}`}
                          key={item?.navigationTitle}
                          href={url}
                        >
                          {item?.navigationTitle}
                        </Nav.Link>
                      )
                    })}
                    {languageOptions && (
                      <NavDropdown
                        title={navTitle}
                        id="collasible-nav-dropdown"
                        className="nav-dropdown-wrapper"
                      >
                        {languageOptions?.map(language => (
                          <NavDropdown.Item
                            key={`${language}`}
                            href={`${
                              language === languageOptions[1] ? '/es' : ''
                            }/${slug === 'wellvolution-home' ? '' : slug}`}
                            className={'navbar-lang'}
                            eventKey={language}
                            onSelect={handleLanguageSelect}
                          >
                            {language}{' '}
                            {selectedLanguage === language && checkmark && (
                              <FontAwesomeIcon
                                className="selected-lang-fa-icon"
                                icon={faCheck}
                              />
                            )}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

export const mapHeaderProps = props => props
